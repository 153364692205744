import React from "react";
import { graphql, Link } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";

import PortableText from "../components/portableText";

import SEO from "../components/seo";
import Layout from "../containers/layout";
import Section from "../components/section";
import Showreel from "../components/showreel";
import Container from "../components/container";

import styles from "./director.module.css";

export const query = graphql`
  query DirectorTemplateQuery($id: String!) {
    director: sanityDirector(id: { eq: $id }) {
      id
      tags
      title
      website
      showreelVimeoId
      slug {
        current
      }
      image {
        asset {
          url
        }
      }
      _rawContent
    }
  }
`;

const DirectorTemplate = (props) => {
  const { data, errors } = props;
  const director = data && data.director;
  const { title, image, tags, website, showreelVimeoId, _rawContent } = director;

  const renderTags = (tags) => {
    if (!tags.length) return null;
    return (
      <div className={styles.tags}>
        {tags.map((tag) => (
          <div key={tag} className={styles.tag}>
            {tag}
          </div>
        ))}
      </div>
    );
  };

  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {showreelVimeoId && (
        <Showreel src={`https://player.vimeo.com/video/${showreelVimeoId}?background=1`} />
      )}

      <Section>
        <div className={styles.column}>
          <Link to="/meet-our-directors" className={styles.backLink}>
            Back to all directors
          </Link>
          <div className={styles.identity}>
            <img src={image.asset.url} className={styles.shield} alt={`${title}’s shield`} />
            <h1 className={styles.name}>{title}</h1>
          </div>
          {_rawContent && <PortableText blocks={_rawContent} />}
          {renderTags(tags)}
          <a href={website} className={styles.website}>
            {website}
          </a>
        </div>
      </Section>
    </Layout>
  );
};

export default DirectorTemplate;
